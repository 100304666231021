<template>
  <div class="container">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb pl-0 mb-0 pb-2">
            <li class="breadcrumb-item"><a href="/">{{ $t('breadcrumb.main-page') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.authorization') }}</li>
        </ol>
    </nav>
     <div class="title-page-mob">
        {{ $t('breadcrumb.authorization') }}
    </div>
    <div class="row signin-wrapper">
      <div class="col-lg-6 col-md-12 signin-col-logo">
          <div class="signin-logo">
                <img src="/images/qabilet-logo.svg" width="236" height="114" style="max-width: 100%;">
          </div>
      </div>
        <div class="col-lg-6 col-md-12 signin-col-form">
            <div class="forget-password">
                <div class="">
                    <div class="forget-password__title">
                        {{ $t('reset-password.title') }}
                    </div>
                    <div class="forget-password__logo">
                        <img src="/images/Qabilet-Final-logo.svg">
                    </div>
                    <div>
                        <p class="forget-password__desc">{{ $t('reset-password.password-recovery-method') }}</p>
                        <div class="form-group ml-2">
                            <el-radio v-model="method" label="email">{{ $t('reset-password.recovery-via-email') }}</el-radio>
                            <el-radio v-model="method" label="sms">{{ $t('reset-password.recovery-via-sms') }}</el-radio>
                        </div>

                        <div>
                            
                        </div>
                        <div class="form-group">
                            <el-button type="primary" @click="next">{{ $t('reset-password.next-btn') }}</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm'
export default {
  name: 'Login',
  components: {
    LoginForm
  },
  data(){
    return {
      method: 'email',
    }
  },
  methods: {
    next(){
      this.$router.push('/forget-password/' + this.method);
    }
  },
}
</script>

<style >
.signin-wrapper {
    padding-bottom: 85px;
    padding-top: 35px;
}
.signin-logo {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
    height: 377px;
    width: 443px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}
.forget-password {
    background: #FFFFFF;
    border: 1px solid rgba(224, 224, 224, 0.15);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 30px;
    position: relative;
    height: 377px;
    width: 384px;
}
.forget-password__title {
    color: #2C2C2C;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 7px;
    position: relative;
}
.forget-password__title:before {
    content: '';
    position: absolute;
    top: 23px;
    right: 0;
    bottom: 0;
    width: 155px;
    left: 0;
    background: linear-gradient(90deg, #31456F 0%, #03C4F7 100%);
}
.forget-password__logo {
    position: absolute;
    top: 20px;
    right: 30px;
}
.forget-password__desc {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #2C2C2C;
    margin-bottom: 17px;
    margin-top: 42px;
}
.forget-password .el-radio__inner {
    width: 16px;
    height: 16px;
}
.forget-password .el-radio__inner::after {
    width: 8px;
    height: 8px;
}
@media screen and (max-width: 991px) {
  .signin-logo {
        margin-bottom: 43px;
        width: 100%;
        height: 233px;
    }
}
</style>
